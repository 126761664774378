var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.$options.components['advertisement-loading'] ? [_vm.isLoading ? _c('advertisement-loading') : _vm._e()] : _vm._e(), _c('b-container', [_c('b-row', [_c('b-col', {
    staticClass: "ml-5",
    attrs: {
      "md": "5"
    }
  }, [_c('h5', {
    staticClass: "heading"
  }, [_vm._v("Create Instore Order")]), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Enter Shop No"
    },
    model: {
      value: _vm.shopName,
      callback: function callback($$v) {
        _vm.shopName = $$v;
      },
      expression: "shopName"
    }
  }), _c('svg', {
    staticClass: "search-icon",
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    },
    on: {
      "click": _vm.onClickSearch
    }
  }, [_c('path', {
    attrs: {
      "d": "M8.09048 16.181C9.83693 16.181 11.4676 15.6128 12.7933 14.666L17.7801 19.6528C18.0116 19.8843 18.3167 20 18.6323 20C19.3161 20 19.8106 19.474 19.8106 18.8006C19.8106 18.485 19.7054 18.1904 19.474 17.959L14.5187 12.9932C15.5602 11.6255 16.181 9.93161 16.181 8.09048C16.181 3.64019 12.5408 0 8.09048 0C3.62967 0 0 3.64019 0 8.09048C0 12.5408 3.62967 16.181 8.09048 16.181ZM8.09048 14.4345C4.6081 14.4345 1.74645 11.5623 1.74645 8.09048C1.74645 4.61862 4.6081 1.74645 8.09048 1.74645C11.5623 1.74645 14.4345 4.61862 14.4345 8.09048C14.4345 11.5623 11.5623 14.4345 8.09048 14.4345Z",
      "fill": "#979797",
      "fill-opacity": "0.6"
    }
  })]), _vm.searchSeller && _vm.show ? _c('div', {
    staticClass: "add-background d-flex ml-3"
  }, [_c('img', {
    staticClass: "ml-2",
    attrs: {
      "width": "120px",
      "src": this.searchSeller.organization.logo.url,
      "alt": ""
    }
  }), _c('div', [_c('span', {
    staticClass: "shop-name"
  }, [_vm._v(_vm._s(_vm.searchSeller.office.officeName))]), _c('br'), _c('span', {
    staticClass: "name"
  }, [_vm._v("Shop #: " + _vm._s(this.shopName))])])]) : _c('div', {
    staticClass: "add-background d-flex justify-content-left"
  }, [_c('img', {
    staticClass: "ml-2",
    attrs: {
      "width": "130px",
      "height": "100px",
      "src": "store-image.PNG",
      "alt": ""
    }
  }), _c('span', {
    staticClass: "shop-name"
  }, [_vm._v("Search Seller")]), _c('br')]), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Enter Order Amount (Excluding Tax)"
    },
    on: {
      "input": function input(e) {
        return _vm.onAddAmount(e);
      }
    },
    model: {
      value: _vm.amount,
      callback: function callback($$v) {
        _vm.amount = $$v;
      },
      expression: "amount"
    }
  }), _c('div', [_vm.show ? _c('div', [_c('p', {
    staticClass: "invoice"
  }, [_vm._v("Invoice Attachment")]), _c('b-form-file', {
    attrs: {
      "state": Boolean(_vm.file1),
      "placeholder": "Upload Invoice...",
      "drop-placeholder": "Drop file here..."
    },
    on: {
      "change": _vm.readLogo
    }
  }), _vm.file1 ? _c('div', [_c('hr', {
    staticClass: "hr2"
  }), _c('div', {
    staticClass: "text-right"
  }, [_c('b-button', {
    staticClass: "rounded-circle tx-20",
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        _vm.file1 = '';
      }
    }
  }, [_vm._v("×")])], 1), _vm.file1 ? _c('div', [_c('b-img', {
    staticClass: "my-2 image w-50",
    attrs: {
      "src": _vm.file1,
      "center": ""
    }
  })], 1) : _vm._e(), _c('hr', {
    staticClass: "hr2"
  })]) : _vm._e()], 1) : _vm._e()])], 1), _c('b-col', {
    staticClass: "margin mt-5",
    attrs: {
      "md": "4"
    }
  }, [_vm.$options.components['address-cart-address'] ? [_c('address-cart-address')] : _vm._e(), _c('b-container', [_c('div', {
    staticClass: "mb-3"
  }, [_c('span', {
    staticClass: "order-header mb-5"
  }, [_vm._v("Price Details")])]), _c('b-row', {
    staticClass: "w-200"
  }, [_c('b-col', [_c('b-card-text', {
    staticClass: "sub-total"
  }, [_vm._v("Total:")])], 1), _c('b-col', [_c('b-card-text', {
    staticClass: "price-details"
  }, [_vm._v("₹ " + _vm._s(this.amount === "" ? "0" : this.amount))])], 1), _c('hr', {
    staticClass: "hr"
  })], 1), _c('b-row', {
    staticClass: "w-200"
  }, [_c('b-col', [_c('b-card-text', {
    staticClass: "sub-total"
  }, [_vm._v("Total Amount:")])], 1), _c('b-col', [_c('b-card-text', {
    staticClass: "price-details"
  }, [_vm._v("₹ " + _vm._s(this.amount === "" ? "0.00" : this.amount))])], 1)], 1)], 1), _c('hr', {
    staticClass: "hr2"
  })], 2)], 1)], 1), _c('div', {
    staticClass: "text center button-margin"
  }, [_c('b-button', {
    staticClass: "mb-2 w-auto",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.submitApproval
    }
  }, [_vm._v(" Submit For Approval")])], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }